import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  SvgIcon,
  ToggleButton,
  Typography,
} from "@mui/material";
import DotsHorizontalIcon from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import Edit05Icon from "@untitled-ui/icons-react/build/esm/Edit05";
import React, {useState} from "react";
import {usePopover} from "src/hooks/use-popover";
import {useTranslation} from "react-i18next";
import {SlideUpTransition} from "src/components/slide-up-transition";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {StyledToggleButtonGroup} from "src/components/styled-toggle-button-group";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import Select from "@mui/material/Select";

export const AssignedBatchedActions = props => {
  const {participantRequestId, batchId, currentStatus} = props;
  const {t} = useTranslation();
  const popover = usePopover();
  const [projectBatchStatus, setProjectBatchStatus] = useState(currentStatus);
  const [editStatusDialog, setEditStatusDialog] = useState(false);
  const [editStatusButtonLoading, setEditStatusButtonLoading] = useState(false);

  const handleEditStatusButton = _ => {
    setEditStatusDialog(true);
    popover.handleClose();
  };

  const handleUpdateBatchStatus = _ => {
    setEditStatusButtonLoading(true);
    const payload = {
      batch_id: batchId,
      status: projectBatchStatus,
    };
    superParticipantRequestService
      .setProjectAssignmentBatchStatus(participantRequestId, payload)
      .then(_ => {
        setEditStatusButtonLoading(false);
        setEditStatusDialog(false);
      });
  };

  return (
    <>
      <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
        <SvgIcon>
          <DotsHorizontalIcon />
        </SvgIcon>
      </IconButton>
      <Popover
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={popover.handleClose}
        open={popover.open}
      >
        <MenuItem
          disableGutters
          sx={{cursor: "pointer", p: 2}}
          onClick={handleEditStatusButton}
        >
          <ListItemIcon>
            <SvgIcon>
              <Edit05Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={t("editStatus")} />
        </MenuItem>
      </Popover>

      {/* EDIT STATUS DIALOG */}
      <Dialog
        open={editStatusDialog}
        TransitionComponent={SlideUpTransition}
        keepMounted
        // onClose={handleClose}
      >
        <DialogTitle>{t("updateBatchProjectStatus")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb: 2}}>
            <Select
              value={projectBatchStatus}
              onChange={e => setProjectBatchStatus(e.target.value)}
              label="Gender"
            >
              <MenuItem value="soon">{t("soon")}</MenuItem>
              <MenuItem value="active">{t("active")}</MenuItem>
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditStatusDialog(false)}>{t("cancel")}</Button>
          <Button
            variant="contained"
            onClick={handleUpdateBatchStatus}
            disabled={editStatusButtonLoading}
          >
            {editStatusButtonLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("update")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
