export class ParticipantRequest {
  constructor(participantRequest) {
    this.id = participantRequest.id;
    this.project_title = participantRequest.project_title;
    this.status = participantRequest.status;
    this.requested_participant_count = participantRequest.requested_participant_count;
    this.description = participantRequest.description;
    this.genders = participantRequest.genders;
    this.ages = participantRequest.ages.map(
      ageRequest =>
        `${ageRequest.min} - ${ageRequest.max !== null ? ageRequest.max : "+"}`,
    );
    this.email = participantRequest.email;
    this.team_name = participantRequest.team_name;
  }
}

export class ParticipantRequestDetails {
  constructor(participantRequest) {
    this.id = participantRequest.id;
    this.projectId = participantRequest.project_id;
    this.projectTitle = participantRequest.project_title;
    this.projectQuestions = participantRequest.project_questions;
    this.status = participantRequest.status;
    this.requestedCountParticipantCount = participantRequest.requested_participant_count;
    this.requestedGenders = participantRequest.genders.join(", ");
    this.requestedAges = participantRequest.ages
      .map(
        ageRequest =>
          `${ageRequest.min} - ${ageRequest.max !== null ? ageRequest.max : "+"}`,
      )
      .join(", ");
    this.description = participantRequest.description;
    this.genders = participantRequest.genders;
    this.ages = participantRequest.ages;
    this.email = participantRequest.email;
    this.team_name = participantRequest.team_name;
  }
}
