import React, {useEffect} from "react";
import {useRouter} from "src/hooks/use-router";
import {useAuth} from "src/hooks/use-auth";
import {useLocation} from "react-router-dom";
import {paths} from "src/pages/paths";

export const AuthGuard = props => {
  const {children} = props;
  const router = useRouter();
  const auth = useAuth();
  const location = useLocation();

  const redirectLocation =
    !!location.pathname && location.pathname !== paths.dashboard.surveys.list
      ? `?r=${location.pathname}`
      : "";

  useEffect(() => {
    if (!auth.isLoggedIn()) {
      router.push(`/auth/login${redirectLocation}`);
    }
  }, [auth.user, router]);

  return <>{children}</>;
};
