import TextInputIcon from "@untitled-ui/icons-react/build/esm/TextInput";
import Dotpoints02 from "@untitled-ui/icons-react/build/esm/Dotpoints02";
import ImageIndentLeft from "@untitled-ui/icons-react/build/esm/ImageIndentLeft";

export const questionType = {
  MultipleChoice: "multiple_choice",
  ShortText: "short_text",
  TextGraphic: "text_graphic",
};

export const questionTypeMap = [
  {
    label: "MultipleChoice",
    value: questionType.MultipleChoice,
    icon: <Dotpoints02 />,
  },
  {
    label: "ShortText",
    value: questionType.ShortText,
    icon: <TextInputIcon />,
  },
  {
    label: "TextGraphic",
    value: questionType.TextGraphic,
    icon: <ImageIndentLeft />,
  },
];

export const getQuestionTypeLabel = (type) => {
  const questionTypeItem = questionTypeMap.find(item => item.value === type);
  return questionTypeItem ? questionTypeItem.label : '';
};