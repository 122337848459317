import {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Box,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ParticipantRequest} from "src/entities/project/participant-request";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {paths} from "src/pages/paths";

export const ParticipantRequests = () => {
  const {t} = useTranslation();
  const [participantRequests, setParticipantRequests] = useState([]);
  const navigate = useNavigate();

  const fetchParticipantRequests = async _ => {
    superParticipantRequestService.listParticipantRequests(1).then(responseData => {
      const requests = responseData.results.map(data => new ParticipantRequest(data));
      setParticipantRequests(requests);
    });
  };

  useEffect(() => {
    fetchParticipantRequests();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{marginTop: 4, marginBottom: 3}}>
        {t("findParticipantsRequest.findParticipantsRequest")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("findParticipantsRequest.requestedStatus")}</TableCell>
              {/*<TableCell>{t("findParticipantsRequest.requestedDate")}</TableCell>*/}
              <TableCell>{t("findParticipantsRequest.surveyName")}</TableCell>
              <TableCell>{t("findParticipantsRequest.surveyDescription")}</TableCell>
              <TableCell>{t("findParticipantsRequest.requestedGender")}</TableCell>
              <TableCell>{t("findParticipantsRequest.requestedAge")}</TableCell>
              <TableCell>{t("findParticipantsRequest.numberOfParticipants")}</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Team Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participantRequests.map(participantRequest => (
              <TableRow key={participantRequest.id} hover style={{cursor: "pointer"}}>
                <TableCell>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      color={
                        participantRequest.status === "pending" ? "success" : "action"
                      }
                      sx={{marginRight: 1}}
                    />
                    {participantRequest.status}
                  </Box>
                </TableCell>
                {/*<TableCell>{participantRequest.date}</TableCell>*/}
                <TableCell>{participantRequest.project_title}</TableCell>
                <TableCell>{participantRequest.description}</TableCell>
                <TableCell>{JSON.stringify(participantRequest.genders)}</TableCell>
                <TableCell>{JSON.stringify(participantRequest.ages)}</TableCell>
                <TableCell>{participantRequest.requested_participant_count}</TableCell>
                <TableCell>{participantRequest.email}</TableCell>
                <TableCell>{participantRequest.team_name}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      navigate(
                        paths.dashboard.participantRequests.details.replace(
                          ":id",
                          participantRequest.id,
                        ),
                      )
                    }
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
