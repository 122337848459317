import React, {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import {projectService} from "src/services/project/project";
import {useTranslation} from "react-i18next";
import {Card, CardContent, Divider, Grid, Paper, ToggleButton} from "@mui/material";
import {styled} from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import {participantRequestService} from "src/services/project/participant-request";
import {ParticipantRequest} from "src/entities/project/participant-request";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 16,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,

  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      // Apply hover effect when selected
      "&:hover": {
        backgroundColor: theme.palette.primary.main, // Darker shade for hover
      },
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

const ageRanges = {
  "18-24": {min: 18, max: 24},
  "25-34": {min: 25, max: 34},
  "35-44": {min: 35, max: 44},
  "45+": {min: 45, max: null},
};

export const FindParticipants = () => {
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const [surveyListPage, setSurveyListPage] = useState(1);
  const [publishedSurveys, setPublishedSurveys] = useState([]);
  const [hasMorePublishedSurveys, setHasMorePublishedSurveys] = useState(true);
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [participants, setParticipants] = useState("");
  const [genders, setGenders] = useState([]);
  const [ages, setAges] = useState([]);
  const [projectDetails, setProjectDetails] = useState("");
  const [submittedRequests, setSubmittedRequests] = useState([]);
  const [participantsError, setParticipantsError] = useState("");

  const handleAges = (event, newAges) => {
    if (newAges) {
      setAges(newAges);
    }
  };

  const handleGenders = (event, newAlignments) => {
    if (newAlignments) {
      setGenders(newAlignments);
    }
  };

  const fetchParticipantRequests = async _ => {
    participantRequestService.listParticipantRequests(1).then(responseData => {
      const participantRequests = responseData.results.map(
        data => new ParticipantRequest(data),
      );
      setSubmittedRequests(participantRequests);
    });
  };

  const fetchPublishedSurveys = async page => {
    setLoadingSurvey(true);
    try {
      const filters = {is_published: true};
      projectService.listProjects(page, filters).then(res => {
        const newSurveys = res.results.map(res => ({id: res.id, title: res.title}));
        setPublishedSurveys(prevSurveys => [...prevSurveys, ...newSurveys]);
        if (res.next === null) {
          setHasMorePublishedSurveys(false);
        }
      });
    } catch (error) {
      console.error("Error fetching options", error);
    }
    setLoadingSurvey(false);
  };

  useEffect(() => {
    fetchParticipantRequests();
  }, []);

  useEffect(() => {
    fetchPublishedSurveys(surveyListPage);
  }, [surveyListPage]);

  const handleSelectSurvey = event => {
    setSelectedSurvey(event.target.value);
  };

  const handleScroll = event => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom && hasMorePublishedSurveys && !loadingSurvey) {
      setSurveyListPage(prevPage => prevPage + 1); // Load next page
    }
  };

  const handleParticipantsChange = event => {
    const value = event.target.value;
    setParticipants(value); // Always set the input value to make it visible
    if (/^\d*$/.test(value)) {
      setParticipantsError(""); // Clear the error if the value is valid
    } else {
      setParticipantsError(t("numberTypeError")); // Set an error message for invalid input
    }
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    const payload = {
      project_id: selectedSurvey,
      requested_participant_count: participants,
      description: projectDetails,
      genders: genders,
      ages: ages.map(ageRange => ageRanges[ageRange]),
    };
    participantRequestService
      .createParticipantRequest(payload)
      .then(createdParticipantRequest => {
        setSubmitLoading(false);
        setSelectedSurvey("");
        setParticipants("");
        setProjectDetails("");
        setGenders([]);
        setAges([]);
        setSubmittedRequests(prevData => [
          ...prevData,
          new ParticipantRequest(createdParticipantRequest),
        ]);
      });
  };

  const isFormValid = () => {
    return (
      selectedSurvey &&
      participants &&
      !participantsError &&
      genders &&
      ages.length > 0 &&
      projectDetails
    );
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4">{t("findparticipants")}</Typography>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 4,
              m: 4,
            }}
          >
            <Container maxWidth="lg">
              <Stack spacing={4} alignItems="center">
                <Stack
                  direction={{xs: "column", md: "row"}}
                  spacing={8}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box textAlign="center">
                    <img src="/assets/svg/numberone.svg" alt="Step 1" />
                    <Typography variant="h6" color={"#000066"} sx={{mt: 1}}>
                      {t("selectTheSurveyYouWantToDistribute")}
                    </Typography>
                  </Box>

                  <Box>
                    <img src="/assets/svg/firstArrow.svg" alt="First Arrow" />
                  </Box>

                  <Box textAlign="center">
                    <img src="/assets/svg/numbertwo.svg" alt="Step 2" />
                    <Typography variant="h6" color={"#000066"} sx={{mt: 1}}>
                      {t("selectParticipantDemographics")}
                    </Typography>
                  </Box>

                  <Box>
                    <img src="/assets/svg/secondArrow.svg" alt="Second Arrow" />
                  </Box>

                  <Box textAlign="center">
                    <img src="/assets/svg/numberthree.svg" alt="Step 3" />
                    <Typography variant="h6" color={"#000066"} sx={{mt: 1}}>
                      {t("sendToAnAgentToGetAQuote")}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Container>
          </Box>

          <Card
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              px: 4,
              py: 2,
              my: 4,
            }}
          >
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("findParticipants.selectTheSurvey")}
            </Typography>

            <Box
              sx={{display: "flex", flexDirection: "column", flexGrow: 1, ml: 2, p: 2}}
            >
              <Box sx={{padding: "0"}}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{t("findParticipants.selectTheSurvey")}</InputLabel>
                  <Select
                    labelId="infinite-scroll-select-label"
                    value={selectedSurvey}
                    onChange={handleSelectSurvey}
                    MenuProps={{
                      PaperProps: {
                        onScroll: handleScroll,
                        style: {maxHeight: 300},
                      },
                    }}
                    fullWidth
                  >
                    {publishedSurveys.map((survey, index) => (
                      <MenuItem key={index} value={survey.id}>
                        {survey.title}
                      </MenuItem>
                    ))}
                    {loadingSurvey && (
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              px: 4,
              py: 2,
              my: 4,
            }}
          >
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("findParticipants.numberofParticipants")}
            </Typography>

            <Box
              sx={{display: "flex", flexDirection: "column", flexGrow: 1, ml: 2, p: 2}}
            >
              <Box sx={{padding: "0"}}>
                <TextField
                  fullWidth
                  label={t("findParticipants.numberofParticipants")}
                  variant="outlined"
                  value={participants}
                  onChange={handleParticipantsChange}
                  error={!!participantsError}
                  helperText={participantsError}
                />
              </Box>
            </Box>
          </Card>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} xs={6}>
              <Card
                sx={{
                  width: "100%",
                  alignItems: "center",
                  px: 4,
                  py: 2,
                  mb: 4,
                }}
              >
                <Typography variant="h6" component="div" sx={{py: 1}}>
                  {t("findParticipants.selectGender")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
                >
                  {t("findParticipants.pleaseSelectGender")}
                </Typography>
                <StyledToggleButtonGroup
                  size="small"
                  value={genders}
                  onChange={handleGenders}
                  aria-label="gender alignment"
                >
                  <ToggleButton value="Male" aria-label="male" sx={{px: 2}}>
                    <Typography fontSize={13} fontWeight="bold">
                      {t("male")}
                    </Typography>
                  </ToggleButton>

                  <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

                  <ToggleButton value="Female" aria-label="female" sx={{px: 2}}>
                    <Typography fontSize={13} fontWeight="bold">
                      {t("female")}
                    </Typography>
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Card>
            </Grid>

            <Grid item xl={6} lg={6} md={6} xs={6}>
              <Card
                sx={{
                  width: "100%",
                  alignItems: "center",
                  px: 4,
                  py: 2,
                  mb: 4,
                }}
              >
                <Typography variant="h6" component="div" sx={{py: 1}}>
                  {t("findParticipants.selectAgeGroups")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
                >
                  {t("findParticipants.pleaseSelectAge")}
                </Typography>

                <StyledToggleButtonGroup
                  size="small"
                  value={ages}
                  onChange={handleAges}
                  aria-label="ages"
                >
                  <ToggleButton value="18-24" aria-label="18-24" sx={{px: 2}}>
                    <Typography fontSize={13} fontWeight="bold">
                      18-24
                    </Typography>
                  </ToggleButton>
                  <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />
                  <ToggleButton value="25-34" aria-label="25-34" sx={{px: 2}}>
                    <Typography fontSize={13} fontWeight="bold">
                      25-34
                    </Typography>
                  </ToggleButton>
                  <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />
                  <ToggleButton value="35-44" aria-label="35-44" sx={{px: 2}}>
                    <Typography fontSize={13} fontWeight="bold">
                      35-44
                    </Typography>
                  </ToggleButton>
                  <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />
                  <ToggleButton value="45+" aria-label="45+" sx={{px: 2}}>
                    <Typography fontSize={13} fontWeight="bold">
                      45+
                    </Typography>
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Card>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Card
              sx={{
                width: "100%",
                alignItems: "center",
                px: 4,
                py: 2,
              }}
            >
              <Typography variant="h6" component="div" sx={{py: 1}}>
                {t("findParticipants.projectDetails")}
              </Typography>
              <Typography
                variant="body2"
                sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
              >
                {t("findParticipants.weCanFindParticipants")}
              </Typography>

              <TextField
                fullWidth
                label={t("findParticipants.projectDetails")}
                multiline
                rows={4}
                variant="outlined"
                value={projectDetails}
                onChange={e => setProjectDetails(e.target.value)}
              />
            </Card>
          </Grid>
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              size="large"
              sx={{backgroundColor: "#ad1445", color: "white", m: 3, px: 8}}
              onClick={handleSubmit}
              disabled={!isFormValid()} // Disable button if form is not valid
            >
              {submitLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("submittedRequests.submit")
              )}
            </Button>
          </Grid>
          <Stack spacing={4} alignItems="center">
            {/* Submitted Requests Table */}
            <Box width="100%" mt={4}>
              <Typography variant="h6" sx={{fontWeight: "bold"}}>
                {t("submittedRequests.submittedRequests")}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("submittedRequests.survey")}</TableCell>
                    <TableCell>{t("submittedRequests.participants")}</TableCell>
                    <TableCell>{t("submittedRequests.gender")}</TableCell>
                    <TableCell>{t("submittedRequests.age")}</TableCell>
                    <TableCell>{t("submittedRequests.projectDetails")}</TableCell>
                    <TableCell>{t("submittedRequests.status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submittedRequests.length > 0 ? (
                    submittedRequests.map((request, index) => (
                      <TableRow key={index}>
                        <TableCell>{request.project_title}</TableCell>
                        <TableCell>{request.requested_participant_count}</TableCell>
                        <TableCell>{JSON.stringify(request.genders)}</TableCell>
                        <TableCell>{JSON.stringify(request.ages)}</TableCell>
                        <TableCell>{request.description}</TableCell>
                        <TableCell>{request.status}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        {t("submittedRequests.noSubmittedRequestsYet")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
