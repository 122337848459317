export const enTranslations = {
  mySurveys: "My Surveys",
  newSurvey: "New Survey",
  AddRandomizer: "AddRandomizer",
  Turkish: "Turkish",
  English: "English",
  mainPage: {
    howToUse: "How to use our platform?",
    inNumbers: "In Numbers",
    register: "Register",
    prepareSurveys: "Prepare The Surveys",
    reachOutParticipants: "Reach Out To Participants",
    registerDescr:
      "As a first step, register on our platform and get access to the inside.",
    prepareSurveysDescr:
      "Prepare surveys and make use of the user pool to gather responses.",
    reachOutDescr:
      "Publish the survey and reach out to participants to ensure it is completed quickly.",
    participantPool: "Participant Pool",
    surveyDiversity: "Survey Diversity",
    rewardDiversity: "Reward Diversity",
    support: "Support",
    participantPoolDescr: "Many people we can assign to your surveys",
    surveyDiversityDescr: "Too many draft surveys",
    rewardDiversityDescr: "Gift cards, discounts, cash prizes and more you can win",
    supportDescr: "Continuous support for your surveys",
    FAQ: "FAQ",
  },
  newSurveys: {
    createNewSurveyTitle: "Create a new survey",
    createNewSurveyDescription: "Start from scratch or an excel sheet",
    newSurveyFromScratch: "New survey from scratch",
    newSurveyFromExcel: "New Survey From Excel",
    newSurveyFromScratchDescription: "Start from scratch",
    uploadFromExcel: "Upload From Excel",
    selectFromPopularTemplates: "Select From Most Popular Templates",
    demographicPoll: "Demographic Poll",
    customizeDraft: "Customize Draft",
    customerFeedback: "Customer Feedback",
    employeeFeedback: "Employee Feedback",
    startSurvey: "Start",
  },
  findparticipants: "Find Participants",
  selectTheSurveyYouWantToDistribute: "Select The Survey You Want To Distribute",
  selectParticipantDemographics: "Select Participant Demographics",
  sendToAnAgentToGetAQuote: "Click on the submit button to save",
  findParticipants: {
    selectTheSurvey: "Select The Survey",
    numberofParticipants: "Number of Participants",
    pleaseSelectGender: "Please select the gender you want to reach",
    pleaseSelectAge: "Please select the age range you want to reach",
    selectGender: "Select Gender",
    selectAgeGroups: "Select Age Group(s)",
    projectDetails: "Project Details",
    weCanFindParticipants:
      "Let us know more about your project so we can find the suitable participants",

    pleaseSendGender: "Please select the gender you want to add",
    pleaseSendAge: "Please select the age range you want to add",
  },
  findParticipantsRequest: {
    findParticipantsRequest: "Manage Participant Requests",
    requestedStatus: "Request Status",
    requestedDate: "Request Date",
    surveyName: "Survey Name",
    surveyDescription: "Survey Description: ",
    requestedGender: "Requested Gender",
    requestedAge: "Requested Age",
    numberOfParticipants: "Number of Participants",
    participantRequestManagment: "Participant Request Management",
    sendParticipant: "Add Participant",
    takeOutParticipant: "Take Out Participant",
  },

  sendParticipant: {
    selectedRequest: "Selected Request: ",
    surveyTopic: "Survey Topic",
    surveyDuration: "Survey Duration",
    surveyLink: "Survey Link",
    targetNumberOfParticipants: "Targeted Number of Participants",
    assignBatches: "Assign Batches",
    topic: "Topic",
    status: "Status",
    assignedParticipantCount: "Assigned Participant Count",
    durationMinute: "Duration (minute)",
    participantGender: "Participant Gender",
    participantAge: "Participant Age",
    rewardDefinition: "Reward Definition",
    paymentStatus: "Payment Status",
    sendSurvey: "Send Survey",
  },

  takeOutParticipant: {
    selectedGender: "Selected Gender",
    selectedAge: "Selected Age",
    takeOutSurvey: "Take Out Survey",
  },

  surveyStatus: {
    title: "Survey Status ",
    completedTotal: "Completed Total:  ",
    completedCount: "Completed Count: ",
    completedAssignedGroup: "Completed Assigned Group: ",
    createGroup: "Create Group ",
    assignedGroup: "Assigned Group ",
    assignedGroupCount: "Assigned Group Count: ",
    assignedGroupGender: "Assigned Group Gender: ",
    assignedGroupAge: "Assigned Group Age: ",
    requestDetails: "Request Details ",
    requestCount: "Request Count: ",
    requestGender: "Request Gender: ",
    requestAge: "Request Age: ",
    surveyDescription: "Survey Description: ",
  },

  submittedRequests: {
    submittedRequests: "Submitted Requests",
    survey: "Survey",
    participants: "Participants",
    gender: "Gender",
    age: "Age",
    projectDetails: "Project Details",
    status: "Status",
    noSubmittedRequestsYet: "No submitted requests yet.",
    submit: "Submit",
  },

  settingcategory: "Settings",
  none: "None",
  male: "Male",
  female: "Female",
  other: "Other",
  all: "All",
  editInfo: {
    editInfo: "Edit Info",
    mail: "Mail",
    phoneNumber: "Phone number (0532 000 00 00)",
    gender: "Gender",
    birthday: "Birthday",
    country: "Country",
    province: "Province",
    editAccountInformation: "Edit Account Information",
    saveAccountInformation: "Save Account Information",
    changePassword: "Change Password",
    cancel: "Cancel",
    delete: "Delete",
    changeCommunicationPreferences: "Change Communication Preferences",
    deleteAccountConfirmation: "Are you sure you want to delete your account?",
    deleteAccountConfirmationDescription:
      "Deleting your account is irreversible. All your data will be permanently removed. Are you sure you want to proceed?",
    deleteAccount: "Delete Account",
    changePasswordConfirmation: "Are you sure you want to change your password?",
    changePasswordConfirmationDescription:
      "Changing your password will require you to log in again. Are you sure you want to proceed?",
  },
  excellToSurvey: "Excel to Survey",

  tableHeaders: {
    title: "Title",
    isPublished: "Activity Status",
    questions: "Questions",
    responses: "Responses",
    completion: "Completion Rate",
    created: "Created",
    updated: "Updated",
    actions: "Actions",
  },
  prev: "Previous Button",
  next: "Next Button",
  publish: "Publish",
  noDataFound: "No data found",
  published: "Published",
  draft: "Draft",
  settings: {
    surveyTitle: "Survey Title",
    open: "Open",
    close: "Close",
  },
  block: {
    enterBlockName: "Enter Block Name",
  },
  buttons: {
    downloadDraftExcel: "Draft Excel File",
    UploadDraftExcel: "Edited Excel File",
    yourDraftExcel: "Your Excel File",
  },
  step: "Step",
  hasBeenUploaded: "Has Been Uploaded",
  writeToExcelFormat: "Write or Paste your questions in excel file",
  uploadQuestionsFromExcel:
    "Upload your survey questions directly to the platform from Excel",
  downloadDraftExcelDirective:
    "You can download the draft excel to copy paste your own questions. You can manage question and block settings from this excel, such as required, randomization, multiple answer permision.",
  downloadExcelFormat: "Download Excel Format",
  importExcelDirective:
    " Convert questions in Excel file into survey. Please define your survey name and upload your edited excel file.",
  importFromExcelFile: "Import From Excel File",
  importExcelError: "There are invalid questions in row(s) bypassed. Index: ",
  defineEditedExcelFile:
    "Please define your survey name and upload your edited excel file.",
  importExcelButtonLabel: "Excel File",
  importExcelDialogTitle: "Survey From Excel File",
  importExcelNameLabel: "Survey Name",
  importFileButtonLabel: "Import File",
  createSurveyButtonLabel: "Create",
  createSurveyDialogTitle: "Create a survey from scratch",
  nameInputLabel: "Name",
  createButtonLabel: "Create",
  cancelButtonLabel: "Back",

  copyLink: "Copy Link",
  copySurvey: "Copy Survey",
  delete: "Delete",
  deleteSurveyTitle: "Delete Survey",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} survey will be deleted permanently. Are you sure?",

  cancel: "Cancel",
  apply: "Apply",

  sections: {
    survey: "Survey",
    preview: "Preview",
    distributions: "Distributions",
    results: "Results",
    analyticsDashboard: "Analytics Dashboard",
  },
  basicSettings: {
    title: "Basic Settings",
    language: "Language",
    languageDescription:
      "We recommend selecting the language you wrote your survey in. Your response data will also be displayed in this language.",
    previousButton: "Previous Button",
    previousButtonDescription: "Enable/Disable previous button.",
    ipBlock: "IP Block",
    ipBlockDescription: "Block multiple responses from the same IP.",
    shareLink: "Add Share Survey Link",
    shareLinkDescription: "Show a shareable link at the end of the survey.",
  },

  writeSomething: "Write something",
  unavailableSurveyMessage: "Unavailable Survey Message",
  buttonLabels: {
    update: "Update",
    reset: "Reset",
  },
  blockSettings: {
    block: "Block",
    editBlock: "Edit Block",
    displayLogicWarning:
      "Attempting to use display logic and randomization within the same block. This combination may not function as expected.",
    randomizeQuestions: "Randomize Questions",
    addPageBreak: "Add Page Break to all Questions",
    makeAllRequired: "Make All Questions Required",
    allowMultipleAnswers: "Allow Multiple Answers",
    otherButton: "Other Button",
    carryChoices: "Carry Choices",
    skipLogic: "Skip Logic",
    selectAnOption: "Select An Option",
    selectOperand: "Select Operand",

    noMultipleQuestions: "No Multiple Questions",
    choices: "Choices",

    selectAQuestion: "Select A Question",
    skipTo: "Skip To",
    endOfSurvey: "End Of Survey",
    endOfBlock: "End Of Block",
  },
  flow: {
    flowList: "Flow List",
    whatDoYouWantToAdd: "What do you want to add?",
    moveButton: "Move",
  },
  results: {
    users: "Users",
    completed: "Completed",
    startDate: "Start Date",
    endDate: "End Date",
    duration: "Duration (s)",
    status: "Status",
  },
  chartType: {
    columnChart: "Column Chart",
    barChart: "Bar Chart",
    pieChart: "Pie Chart",
    lineChart: "Line Chart",
    areaChart: "Area Chart",
    scatterChart: "Scatter Chart",
    splineChart: "Spline Chart",
    bubbleChart: "Bubble Chart",
    polarChart: "Polar Chart",
    radarChart: "Radar Chart",
  },
  editor: {
    endPageLabel: "End Page",
    createBlockTooltip: "Create Question Block",
    editBlockTooltip: "Edit Question Block",
    deleteBlockTooltip: "Delete Question Block",
    blockDeletionConfirmation: "block will be permanently deleted. Are you sure?",
  },
  analyticsDashboard: {
    firstQuestionForChart: "Select First Question for chart",
    addSecondQuestionforChart: "Add Second Question for Chart",
    selectSecondQuestionForchart: "Select Second Question for chart",
    selectAQuestion: "Select a question",
    chartTitle: "Chart Title",
    unitType: "Unit Type",
    chartType: "Chart Type",
    highchartsWithMUI: "Highcharts With MUI",
  },
  theme: {
    defaultTheme: "Default Theme",
    brandDarkTheme: "Brand Dark Theme",
    brandLightTheme: "Brand Light Theme",
    grayTheme: "Gray Theme",
    darkUnicaTheme: "Dark Unica Theme",
    sandSignikaTheme: "Sand Signika Theme",
    oceanBreezeTheme: "Ocean Breeze Theme",
    sunsetGlowTheme: "Sunset Glow Theme",
  },

  downloadResults: "Download Results",
  resetSurvey: "Reset Survey",
  questionLabel: "Question Label",
  duplicate: "Duplicate",
  remove: "Remove",
  shuffleQuestion: "Shuffle Question",
  deleteQuestion: "Delete Question",
  ignoreRequire: "Ignore Require",
  ignorePreviewData: "Ignore Preview Data",
  addOption: "Add Option",

  label: "Default Question Label",
  choice1: "Choice 1",
  choice2: "Choice 2",
  choice3: "Choice 3",
  defaultQuestion: "Default Question Title",
  save: "Save",
  reset: "Reset",

  isEqual: "Is Equal",
  isNotEqual: "Is Not Equal",
  isdisplayed: "Is Displayed",
  isNotdisplayed: "Is Not Displayed",
  isEmpty: "Is Empty",
  isNotEmpty: "Is Not Empty",
  isSelected: "Is Selected",
  isNotSelected: "Is Not Selected",

  allOptions: "All Options",
  selectedOptions: "Selected Options",
  unselectedOptions: "Unselected Options",

  key: "Key",
  value: "Value",

  EditQuestion: "Edit Question",
  QuestionType: "Question Type",
  logicType: "Logic Type",

  embeddedData: "Embedded Data",

  Text: "Text",
  Settings: "Settings",
  Behaviours: "Behaviours",
  MultipleChoice: "Multiple Choice",
  ShortText: "Short Text",
  TextGraphic: "Text / Graphic",
  SurveyQuestion: "Survey Question",
  EmbeddedDataField: "Embedded Data Field",
  EditQuestionTextDialogTitle: "Edit Question Text",
  PlaceholderWriteSomething: "Write something",
  CancelButton: "Cancel",
  notImplementedQuestionBehaviour: "Not Implemented Question Behaviour Settings",

  SaveButton: "Save",
  RequiredLabel: "Required",
  ContentTypeLabel: "Content Type",
  TextLabel: "Text",
  NumberLabel: "Number",
  EmailAddressLabel: "Email Address",
  error: "Hata",
  RequiredQuestionError: "This question is required.",
  textInputTypeError: "Text input is invalid.",
  integerInputTypeError: "Number input is invalid.",
  phoneNumberTypeError: "Please enter a valid phone number in the format 0532 052 52 82.",
  numberTypeError: "Please enter a valid number",
  emailInputTypeError: "Email input is invalid.",
  DisplayLogic: "Display Logic",
  DownloadQRCode: "Download QR Code",
  CopyLink: "Copy Link",
  AddElement: "Add Element",
  AddCondition: "Add Condition",
  EditCondition: "Edit Condition",

  CloseWindow: "Close Window",
  Link: "Link",
  QRCode: "QR Code",
  CreateQuestion: "Create Question",
  AddPageBreak: "+ Add Page Break",
  LogoutButton: "Logout",
  AddBlock: "Add Block",
  AddBranch: "Add Branch",
  successfullyUpdated: "Successfully Updated",
  addBelow: "Add Below",

  surveyIsNotPublished: "To distribute the survey, please publish it first.",

  builder: "Builder",
  surveyFlow: "Survey flow",
  surveyOptions: "Survey options",
  design: "Design",

  auth: {
    login: "Log in",
    register: "Register",
    emailIsRequired: "Please enter your email address.",
    passwordIsRequired: "Please enter your password",
    alreadyhaveaccount: "Already Have Account",
    passwordMustBeValid: "Must be a valid email",
    emailOrPassIncorrect: "Email or password is incorrect, Please try again.",

    resetPassword: {
      title: "Reset Password",
      textLabel: "Email Address or Phone Number",
      buttonLabel: "Send activation link",
      invalidInputMessage: "Please provide a valid email or phone number (05329993377).",
      confirmationMessage:
        "If your info is in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
    },
    forgotpasswordtitle: "Renew Password",
    forgotpasswordlink: "Sent a new password",
    emailAddress: "Email or phone number",
    password: "Password",
    Login: "Login",
    continue: "Send activation link",
    sendnewpassword: "Send a new password",
    sentinfo:
      "If your info isn't in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
    passwordsentinfo:
      "If your info isn't in the system, you'll get a new password. You can change it from account settings again. For questions, contact katilimcihavuzu@galataanket.com.",
    forgotPassword: "Forgot password?",
    noAccount: "Don't have an account?",
  },
  designTab: {
    logo: "Logo",
    general: "General",
    background: "Background",
    style: "Style",
    motion: "Motion",
    similars: {
      left: "Left",
      center: "Center",
      right: "Right",
      top: "Top",
      bottom: "Bottom",
    },
    logoStyles: {
      logoImage: "Logo Image",
      change: "Change",
      placement: "Placement",
      verticalPlacement: "Vertical Placement",
      maxHeight: "Max Height",
      maxWidth: "Max Width",
    },
    generalStyles: {
      nextButtonText: "Next Button Text",
      previousButtonText: "Previous Button Text",
      progressBar: "Progress Bar",
      none: "None",
      withText: "With Text",
      withoutText: "Without Text",
      progressBarPlacement: "Progress Bar Placement",
      progressBarVerticalPlacement: "Progress Bar Vertical Placement",
    },
    styleStyles: {
      primaryColor: "Primary Color",
      secondaryColor: "Secondary Color",
      font: "Font",
      questionFontSize: "Question Font Size (px)",
      answersFontSize: "Answers Font Size (px)",
    },
    backgroundStyles: {
      backgroundColor: "Background Color",
    },
    motionStyles: {
      animationType: "Animation Type",
      slide: "Slide",
      fade: "Fade",
      grow: "Grow",
      zoom: "Zoom",
      collapse: "Collapse",
      slideAnimationDirection: "Slide Animation Direction",
    },
    previewSide: {
      mySurvey: "My Survey",
      sampleSurvey: "Sample Survey",
      viewDesktop: "View Desktop",
      viewMobile: "View Mobile",
      selectSurvey: "Select Survey",
    },
  },
  participantPaymentRequests: {
    participantPaymentRequests: "Participant Payment Requests",
    requestedStatus: "Requested Status",
    participantEmail: "Participant Email",
    participantId: "Participant Id",
    requestedAmount: "Requested Amount",
    requestedMarket: "Requested Market",
    paymentSent: "Payment Sent",
    participantCompletedSurveyDuration: "Duration of Survey Completed",
    participantCompletedSurvey: "Completed Survey Count",
    selectedRequest: "Selected Request",
    requestDetail: "Request Detail",
    confirmPayment: "Confirm Payment",
    minute: "min",
  },

  companyDescription:
    "Galata Anket is an academic and market research company that integrates digital survey tools and provides data-driven consultancy to its clients.",
  platformTour: "Platform Tour",

  professionalSurveyTeam: "A professional team for the most reliable survey experience!",
  dedicatedResearchAndAnalysis:
    "Our team of expert researchers and data analysts works diligently to design and manage surveys tailored to each customer's specific needs and objectives. Our aim is to gather accurate, reliable, and actionable data through online surveys or focus groups.",
  notOnlyDataCollection: "More than just data!",
  clientReportingAndRecommendations:
    "In addition to providing our customers with detailed reports and analyses, we offer alternative suggestions on how they can utilize the compiled information to enhance their organizations and bring them closer to their goals. Furthermore, we present the most accurate planning for implementing these suggestions.",
  dataDrivenDecisions:
    "Growth with Feedback: Stand Out in Competition with the Voice of Customers",
  commitmentToClientSuccess:
    "We strive to make our customers more visible in the competitive market and enable them to hear their own voice of customers more clearly by providing them with high-quality data. Join our mission and discover the power of listening to your customers' feedback!",
  messageSent: "Your message has been sent!",
  messageSendError: "Failed to send message. Please try again later.",
  welcomeContactPage: "Welcome to Our Contact Page!",
  contactInvitation:
    "Feel free to reach out to us with any questions or comments. We’d love to hear from you!",
  contactUs: "Contact Us",
  name: "Name",
  surname: "Surname",
  mail: "Mail",
  message: "Message",
  sending: "Sending ...",
  sendMessage: "Send Message",
  needHelp: "Need Help?",
  home: "Home",
  aboutUs: "About Us",
  contact: "Contact",
  platform: "Platform",
  pages: "Pages",
  websiteDisclosure: "Website Disclosure Text",
  cookiePrivacyPolicy: "Cookie and Privacy Policy",
  dataSubjectApplicationForm: "Data Subject Application Form",
  ourAddress: "Our Address",
  followUs: "Follow Us",
  rightsReserved: "Galata Survey. All rights reserved.",
};
