import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Container,
  Grid,
  Card,
  Divider,
  ToggleButton,
  SvgIcon,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  Person as PersonIcon,
  Group as GroupIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {ParticipantRequestDetails} from "src/entities/project/participant-request";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import {paths} from "src/pages/paths";
import {ProjectAssignmentBatch} from "src/entities/project/project-assignment";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import {RewardCondition} from "src/pages/dashboard/super/participant-requests/reward-condition";
import {RewardCondition as RewardConditionObj} from "src/entities/project/condition/reward-condition";
import {expressionLogicType} from "src/schemas/condition/expression-logic-type";
import {AssignedBatchedActions} from "src/pages/dashboard/super/participant-requests/assigned-batched-actions";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 16,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,

  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      // Apply hover effect when selected
      "&:hover": {
        backgroundColor: theme.palette.primary.main, // Darker shade for hover
      },
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

const IconText = ({icon, label, value}) => (
  <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
    <SvgIcon sx={{mr: 1}}>{icon}</SvgIcon>
    <Typography variant="body1">
      {label}: {value}
    </Typography>
  </Box>
);

const mockData = {
  completedTotal: 89,
  completedAssignedGroup: 27,
  assignedGroupCount: 127,
  assignedGroupMale: 60,
  assignedGroupFemale: 67,
  assignedGroupYoung: 30,
  assignedGroupOld: 40,
  requestCount: 300,
  requestMale: 150,
  requestFemale: 150,
  requestYoung: 100,
  requestOld: 200,
  surveyDescription: "X Company Customer Feedback Survey",
};

const ageRangeMap = {
  "18-24": {min: 18, max: 24},
  "25-34": {min: 25, max: 34},
  "35-44": {min: 35, max: 44},
  "45+": {min: 45, max: null},
};

export const ParticipantRequestDetailsPage = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [participantRequestDetails, setParticipantRequestDetails] = useState(null);
  // Participant Settings
  const [genders, setGenders] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);
  const [participantCount, setParticipantCount] = useState(0);
  // Project Settings
  const [projectTopic, setProjectTopic] = useState("");
  const [projectDuration, setProjectDuration] = useState(0);
  // Reward Settings
  const [projectRewardAmount, setProjectRewardAmount] = useState(0);
  const [projectRewardConditions, setProjectRewardConditions] = useState(null);
  const [projectStatus, setProjectStatus] = useState("active");
  const [activeSection, setActiveSection] = useState("send");
  const [saveRewardConditionLoading, setSaveRewardConditionLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [projectAssignmentBatches, setProjectAssignmentBatches] = useState([]);

  useEffect(() => {
    superParticipantRequestService.getParticipantRequestDetails(id).then(response => {
      const details = new ParticipantRequestDetails(response);
      setParticipantRequestDetails(details);
    });
    superParticipantRequestService.listProjectAssignmentBatches(id, 1).then(response => {
      const batches = response.results.map(res => new ProjectAssignmentBatch(res));
      setProjectAssignmentBatches(batches);
    });
    superParticipantRequestService.getProjectReward(id).then(response => {
      setProjectRewardAmount(response.amount);
      setProjectRewardConditions(new RewardConditionObj(response));
    });
  }, []);

  if (participantRequestDetails === null) {
    return <GifSplashScreen />;
  }

  const handleCondition = (expressionIndex, name, value) => {
    projectRewardConditions.expressions[expressionIndex].setLogicType(name, value);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleLocator = (expressionIndex, name, value) => {
    if (
      name === "left_operand" &&
      projectRewardConditions.expressions[expressionIndex].expression_type ===
        expressionLogicType.QUESTION
    ) {
      projectRewardConditions.expressions[expressionIndex].setQuestionLocator(value);
    }
    projectRewardConditions.expressions[expressionIndex].locator.set(name, value);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleAddExpression = expressionIndex => {
    projectRewardConditions.addExpression(expressionIndex);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleDeleteExpression = expressionIndex => {
    projectRewardConditions.deleteExpression(expressionIndex);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleSetReward = _ => {
    setSaveRewardConditionLoading(true);
    const payload = {
      amount: projectRewardAmount,
      expressions: projectRewardConditions.expressions,
    };
    superParticipantRequestService.setProjectReward(id, payload).then(_ => {
      setSaveRewardConditionLoading(false);
    });
  };

  const handleSectionChange = section => () => setActiveSection(section);

  const handleGenders = (event, newGenders) => {
    if (newGenders) {
      setGenders(newGenders);
    }
  };

  const handleAgeRanges = (event, newAgeRanges) => {
    if (newAgeRanges) {
      setAgeRanges(newAgeRanges);
    }
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    const payload = {
      participant_count: participantCount,
      topic: projectTopic,
      duration: projectDuration,
      status: projectStatus,
      expire_date: null,
      genders: genders,
      ages: ageRanges.map(ageRange => ageRangeMap[ageRange]),
    };
    superParticipantRequestService.createProjectAssignment(id, payload).then(_ => {
      setSubmitLoading(false);
      setGenders([]);
      setAgeRanges([]);
      setParticipantCount(0);
      setProjectTopic("");
      setProjectDuration(0);
      setProjectStatus("active");
    });
  };

  const renderToggleButtons = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 4,
        marginBottom: 2,
      }}
    >
      <Button
        variant={activeSection === "send" ? "contained" : "outlined"}
        color="primary"
        onClick={handleSectionChange("send")}
        sx={{flex: 1, marginRight: 1}}
      >
        {t("findParticipantsRequest.sendParticipant")}
      </Button>
      <Button
        variant={activeSection === "takeOut" ? "contained" : "outlined"}
        color="primary"
        onClick={handleSectionChange("takeOut")}
        sx={{flex: 1, marginLeft: 1}}
      >
        {t("findParticipantsRequest.takeOutParticipant")}
      </Button>
    </Box>
  );

  const renderSendSection = () => (
    <>
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("findParticipants.selectGender")}
            </Typography>
            <Typography
              variant="body2"
              sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
            >
              {t("findParticipants.pleaseSendGender")}
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={genders}
              onChange={handleGenders}
              aria-label="gender alignment"
            >
              <ToggleButton value="Male" aria-label="male" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  {t("male")}
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="Female" aria-label="female" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  {t("female")}
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("findParticipants.selectAgeGroups")}
            </Typography>
            <Typography
              variant="body2"
              sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
            >
              {t("findParticipants.pleaseSendAge")}
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={ageRanges}
              onChange={handleAgeRanges}
              aria-label="ageRanges"
            >
              <ToggleButton value="18-24" aria-label="18-24" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  18-24
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="25-34" aria-label="25-34" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  25-34
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="35-44" aria-label="35-44" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  35-44
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="45+" aria-label="45+" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  45+
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xl={4} lg={4} md={4} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Project Topic
            </Typography>
            <TextField
              fullWidth
              label={"Project Topic"}
              variant="outlined"
              value={projectTopic}
              onChange={e => setProjectTopic(e.target.value)}
            />
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.targetNumberOfParticipants")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.targetNumberOfParticipants")}
              variant="outlined"
              value={participantCount}
              onChange={e => setParticipantCount(e.target.value)}
              type="number"
            />
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.durationMinute")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.durationMinute")}
              variant="outlined"
              value={projectDuration}
              onChange={e => setProjectDuration(e.target.value)}
              type={"number"}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Project Status
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={projectStatus}
              onChange={(e, newStatus) => newStatus && setProjectStatus(newStatus)}
              aria-label="project status alignment"
              exclusive={true}
            >
              <ToggleButton value="soon" aria-label="soon" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  Coming Soon
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="active" aria-label="active" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  Active
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit}
          // disabled={!isFormValid()} // Disable button if form is not valid
        >
          {submitLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("findParticipantsRequest.sendParticipant")
          )}
        </Button>
      </Box>
    </>
  );

  const renderTakeOutSection = () => (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.targetNumberOfParticipants")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.targetNumberOfParticipants")}
              variant="outlined"
              value={participantCount}
              onChange={e => setParticipantCount(e.target.value)}
              type="number"
            />
          </Card>
        </Grid>
      </Grid>
      {/*<Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>*/}
      {/*  <Button*/}
      {/*    variant="contained"*/}
      {/*    color="primary"*/}
      {/*    onClick={handleAction("Take Out Survey")}*/}
      {/*  >*/}
      {/*    {t("takeOutParticipant.takeOutSurvey")}*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </>
  );

  return (
    <Container maxWidth="lg" sx={{padding: 2}}>
      <Typography variant="h4" gutterBottom>
        {t("findParticipantsRequest.findParticipantsRequest")}
      </Typography>

      <Typography>
        Project:{" "}
        {participantRequestDetails && (
          <Link
            href={paths.surveyBuilder.edit.replace(
              ":uuid",
              participantRequestDetails.projectId,
            )}
          >
            {participantRequestDetails.projectTitle}
          </Link>
        )}
      </Typography>

      <Typography>
        Request Description:{" "}
        {participantRequestDetails && participantRequestDetails.description}
      </Typography>

      <Typography variant="h6" gutterBottom sx={{mt: 2}}>
        {t("surveyStatus.title")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{padding: 2, height: "100%"}}>
            <Typography variant="subtitle1" gutterBottom>
              {t("surveyStatus.completedTotal")}
            </Typography>
            <IconText
              icon={<PersonIcon />}
              label={t("surveyStatus.completedCount")}
              value={mockData.completedTotal}
            />
            <IconText
              icon={<GroupIcon />}
              label={t("surveyStatus.completedAssignedGroup")}
              value={mockData.completedAssignedGroup}
            />
            <Box sx={{display: "flex", justifyContent: "flex-end", mt: 2}}>
              {/*<Button*/}
              {/*  variant="outlined"*/}
              {/*  size="small"*/}
              {/*  onClick={handleAction("Create Group")}*/}
              {/*>*/}
              {/*  {t("surveyStatus.createGroup")}*/}
              {/*</Button>*/}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{padding: 2, height: "100%"}}>
            <Typography variant="subtitle1" gutterBottom>
              {t("surveyStatus.assignedGroup")}
            </Typography>
            <IconText
              icon={<GroupIcon />}
              label={t("surveyStatus.assignedGroupCount")}
              value={mockData.assignedGroupCount}
            />
            <IconText
              icon={<PersonIcon />}
              label={t("surveyStatus.assignedGroupGender")}
              value={`${mockData.assignedGroupMale}, ${mockData.assignedGroupFemale}`}
            />
            <IconText
              icon={<EventIcon />}
              label={t("surveyStatus.assignedGroupAge")}
              value={`${mockData.assignedGroupYoung}, ${mockData.assignedGroupOld}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{padding: 2}}>
            <Typography variant="subtitle1" gutterBottom>
              {t("surveyStatus.requestDetails")}
            </Typography>
            {participantRequestDetails === null ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <>
                <IconText
                  icon={<GroupIcon />}
                  label={t("surveyStatus.requestCount")}
                  value={participantRequestDetails.requestedCountParticipantCount}
                />
                <IconText
                  icon={<PersonIcon />}
                  label={t("surveyStatus.requestGender")}
                  value={participantRequestDetails.requestedGenders}
                />
                <IconText
                  icon={<EventIcon />}
                  label={t("surveyStatus.requestAge")}
                  value={participantRequestDetails.requestedAges}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* ========= REWARD ========= */}
      <Grid container spacing={3}>
        <Grid item xl={8} lg={8} md={8} xs={8}>
          <Card sx={{width: "100%", alignItems: "center", px: 3, py: 2, my: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Reward Condition
            </Typography>
            {projectRewardConditions && (
              <RewardCondition
                questions={participantRequestDetails.projectQuestions}
                condition={projectRewardConditions}
                handleCondition={handleCondition}
                handleLocator={handleLocator}
                handleAddExpression={handleAddExpression}
                handleDeleteExpression={handleDeleteExpression}
              />
            )}
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={4}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, my: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.rewardDefinition")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.rewardDefinition")}
              variant="outlined"
              value={projectRewardAmount}
              onChange={e => setProjectRewardAmount(e.target.value)}
              type={"number"}
            />
          </Card>
        </Grid>
      </Grid>
      <Box sx={{display: "flex", justifyContent: "flex-start", marginTop: 2}}>
        <Button onClick={handleSetReward} variant="contained">
          {saveRewardConditionLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Set Reward"
          )}
        </Button>
      </Box>

      {renderToggleButtons()}

      {activeSection === "send" ? renderSendSection() : renderTakeOutSection()}

      <Typography variant="h4" gutterBottom sx={{marginTop: 4, marginBottom: 3}}>
        {t("sendParticipant.assignBatches")}
      </Typography>
      <TableContainer component={Paper} sx={{marginBottom: 10}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("sendParticipant.topic")}</TableCell>
              <TableCell>{t("sendParticipant.status")}</TableCell>
              <TableCell> {t("sendParticipant.durationMinute")}</TableCell>
              <TableCell>{t("sendParticipant.assignedParticipantCount")}</TableCell>
              <TableCell align="right">{t("tableHeaders.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectAssignmentBatches.map(batch => (
              <TableRow key={batch.batchId} hover style={{cursor: "pointer"}}>
                <TableCell>{batch.topic}</TableCell>
                <TableCell>{batch.status}</TableCell>
                <TableCell>{batch.duration}</TableCell>
                <TableCell>{batch.assignedParticipantCount}</TableCell>
                <TableCell align="right">
                  <AssignedBatchedActions
                    participantRequestId={id}
                    batchId={batch.batchId}
                    currentStatus={batch.status}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
