import React, {useMemo, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {usePathname} from "src/hooks/use-pathname";
import Box from "@mui/material/Box";
import {Stack} from "@mui/system";
import {RouterLink} from "src/components/router-link";
import {AccountButton} from "src/layouts/dashboard/top-nav/buttons/account-button";
import {LanguageSwitchButton} from "src/layouts/dashboard/top-nav/buttons/language-switch-button";
import {paths} from "src/pages/paths";
import {TopNavSection} from "src/layouts/survey-builder/top-nav/top-nav-section";
import {useSections} from "src/layouts/survey-builder/top-nav/use-sections";
import SvgIcon from "@mui/material/SvgIcon";
import Signal01 from "@untitled-ui/icons-react/build/esm/Signal01";
import {useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useAuth} from "src/hooks/use-auth";
import {useTranslation} from "react-i18next";
import {useSurveyBuilder} from "src/hooks/use-survey-builder";

const useCssVars = () => {
  const theme = useTheme();

  return useMemo(() => {
    if (theme.palette.mode === "dark") {
      return {
        "--nav-bg": theme.palette.neutral[800],
        "--nav-color": theme.palette.common.white,
        "--nav-divider-color": theme.palette.neutral[700],
        "--nav-border-color": "transparent",
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.common.white,
        "--nav-item-disabled-color": theme.palette.neutral[500],
        "--nav-item-icon-color": theme.palette.neutral[400],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
        "--nav-item-chevron-color": theme.palette.neutral[600],
        "--nav-scrollbar-color": theme.palette.neutral[400],
        "--nav-flex-direction": "row", // Default flex direction
      };
    } else {
      return {
        "--nav-bg": theme.palette.neutral[800],
        "--nav-color": theme.palette.common.white,
        "--nav-divider-color": theme.palette.neutral[700],
        "--nav-border-color": "transparent",
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.common.white,
        "--nav-item-disabled-color": theme.palette.neutral[500],
        "--nav-item-icon-color": theme.palette.neutral[400],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
        "--nav-item-chevron-color": theme.palette.neutral[600],
        "--nav-scrollbar-color": theme.palette.neutral[400],
        "--nav-flex-direction": "row",
      };
    }
  });
};

export const TopNav = () => {
  const {t, i18n} = useTranslation();

  const [publishLoading, setPublishLoading] = useState(false);
  const pathname = usePathname();
  const cssVars = useCssVars();
  const sections = useSections();
  const auth = useAuth();
  const surveyBuilder = useSurveyBuilder();

  const publishProject = _ => {
    setPublishLoading(true);
    surveyBuilder
      .handlePublishSurvey()
      .then(() => {
        auth.showAlert("success", "Survey published successfully");
        setPublishLoading(false);
      })
      .catch(_ => {
        auth.showAlert("error", "Add flow elements.");
        setPublishLoading(false);
      });
  };

  const isArabic = i18n.language === "ar";
  if (isArabic) {
    // Update styles for mirroring in Arabic
    cssVars["--nav-flex-direction"] = "row-reverse";
  }

  return (
    <Box
      component="header"
      sx={{
        ...cssVars,
        backgroundColor: "var(--nav-bg)",
        borderBottomColor: "var(--nav-border-color)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        color: "var(--nav-color)",
        left: 0,
        position: "sticky",
        top: 0,
        zIndex: theme => theme.zIndex.appBar,
        flexDirection: "var(--nav-flex-direction)", // Apply the flex direction variable
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 1,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Box
            component={RouterLink}
            href={paths.dashboard.surveys.list}
            sx={{
              borderColor: "var(--nav-logo-border)",
              borderRadius: 1,
              borderWidth: 1,
              display: "inline-flex",
              height: 50,
              p: "4px",
              width: 105,
              cursor: "pointer",
              backgroundColor: "white", // Added this line
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={"/Logo_Small.png"}
              alt="Logo"
            />
          </Box>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <LanguageSwitchButton />
          <AccountButton />
        </Stack>
      </Stack>
      <Box
        sx={{
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderTopColor: "var(--nav-divider-color)",
        }}
      >
        <Stack
          alignItems="center"
          component="nav"
          //direction="row"
          spacing={1}
          sx={{
            px: 2,
            py: 1.5,
          }}
        >
          {sections.map((section, index) => (
            <TopNavSection
              items={section.items}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
            />
          ))}

          <Button
            onClick={publishProject}
            variant="contained"
            startIcon={
              publishLoading ? (
                <></>
              ) : (
                <SvgIcon>
                  <Signal01 />
                </SvgIcon>
              )
            }
          >
            {publishLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("publish")
            )}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
